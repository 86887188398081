<template>
    <div class="sm:px-4 px-2 py-4 cd-body bg-white">
        <vs-row>
            <!-- navbar -->
            <vs-col class="sm:px-4 px-2 cd-form-group flex flex-wrap justify-between" vs-w="7" vs-sm="10" vs-xs="12">
                <div class="flex items-end whitespace-no-wrap">
                    <h3>{{ $t('crm.pageTitle') }}</h3>
                    <span class="ml-4 text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="openNewCasePopup(0, null)">{{ $t('crm.addCase') }}</span>
                    <span class="ml-4 text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="closeAllCaseDialog">{{ $t('crm.closeAll') }}</span>
                </div>
                <div class="sm:mt-0 mt-2 w-auto">
                    <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="w-full input-rounded-full" v-model="query" />
                </div>
            </vs-col>
            <!-- content -->
            <vs-col class="list sm:px-2 px-0" vs-w="7" vs-sm="10" vs-xs="12">
                <div class="sm:px-4 px-2 sm:py-4 py-2 m-2" :style="item.status == 0 ? 'background-color: #fee; border: #eee 1px solid;' : ''" v-for="(item, index) in srData" :key="index">
                    <div class="mb-2 flex justify-between items-end">
                        <div class="whitespace-no-wrap cursor-pointer" :class="item.status == 0 ? 'text-primary' : 'font-normal'" @click="goToMr(item)">
                            <span class="mr-2 text-sm">#{{ item.id }}</span>
                            <span class="font-semibold text-lg">{{ item.pname }}</span>
                        </div>
                        <div class="flex">
                            <div class="sm:mr-4 mr-2 flex tems-center cursor-pointer hover:font-semibold whitespace-no-wrap" :class="item.assignFlag ? 'text-grey' : 'text-primary'" @click="showCallPopup(item)">
                                <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-4 h-4"></feather-icon>
                                <span>{{ $t('apptMgmt.call') }}</span>
                            </div>
                            <vs-dropdown ref="myDropdown" class="sm:mr-4 mr-2 cursor-pointer hover:font-semibold" :class="item.assignFlag ? 'disabled text-grey' : 'text-dark'" :disabled="item.assignFlag ? true : false" vs-custom-content vs-trigger-click @click.native="trackDays = 0">
                                <div class="flex items-center" v-if="item.status == 1">
                                    <span>{{ $t('crm.finished') }}</span>
                                    <feather-icon icon="ChevronDownIcon" svg-classes="w-4 h-4" />
                                </div>
                                <div class="text-danger flex items-center" v-else>
                                    <span class="cursor-pointer">{{ $t('crm.track') }}</span>
                                    <span class="ml-1 text-sm cursor-pointer">{{ getTrackDays(item.tracked_at) }}</span>
                                    <feather-icon icon="ChevronDownIcon" svg-classes="w-4 h-4" />
                                </div>
                                <vs-dropdown-menu>
                                    <ul class="whitespace-no-wrap">
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="updateStatus(item.id, 0, index)" v-if="item.status == 0">
                                            <span>{{ $t('crm.finish') }}</span>
                                        </li>
                                        <vs-divider class="my-2" v-if="item.status == 0" />
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="updateStatus(item.id, 1, index)">
                                            <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 1 }) }}</span>
                                        </li>
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="updateStatus(item.id, 3, index)">
                                            <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 3 }) }}</span>
                                        </li>
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="updateStatus(item.id, 5, index)">
                                            <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 5 }) }}</span>
                                        </li>
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="updateStatus(item.id, 7, index)">
                                            <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 7 }) }}</span>
                                        </li>
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="updateStatus(item.id, 30, index)">
                                            <span>{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 30 }) }}</span>
                                        </li>
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white">
                                            <span>{{ $t('crm.tracking') }}</span>
                                            <input type="number" class="ml-2 w-12" @input="updateStatus(item.id, parseInt(trackDays), index, false)" v-model="trackDays" />
                                            <span>{{ $t('crm.days', { days: '' }) }}</span>
                                        </li>
                                    </ul>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                            <vs-dropdown class="cursor-pointer hover:font-semibold" vs-custom-content vs-trigger-click>
                                <div class="flex items-center">
                                    <feather-icon icon="MoreVerticalIcon" svg-classes="w-5 h-5" />
                                </div>
                                <vs-dropdown-menu>
                                    <ul style="min-width: 8rem">
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click.stop="showChatPopup(item.mid, item.pname)">
                                            <span>{{ $t('crm.sendMsg') }}</span>
                                        </li>
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click.stop="showApptPopup(0, item.pid, item.pname)">
                                            <span>{{ $t('crm.agent') }}</span>
                                        </li>
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click.stop="showApptPopup(1, item.pid, item.pname)">
                                            <span>{{ $t('crm.special') }}</span>
                                        </li>
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click.stop="openNewCasePopup(item.pid, item.pname)">
                                            <span>{{ $t('crm.addCase') }}</span>
                                        </li>
                                        <!-- <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" v-if="item.family_data" @click.stop="showContactCaregiverPopup(item.family_data)">
                                            <span>聯繫照護者</span>
                                        </li> -->
                                        <vs-divider v-if="allowshopstatus" />
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="openProductPopup(item.pid, '', item.pname)" v-if="allowshopstatus">
                                            <span>{{ $t('emr.appt.Recommend') }}</span>
                                        </li>
                                        <!-- <div class="pl-2 ml-2 cd-dropdown-children" v-if="contactCaregiverToggle == true">
                                            <ul v-for="(data,index2) in item.family_data" :key="index2">
                                                {{ data.name }}
                                                <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white">
                                                    <span>{{ data.mobile }}</span>
                                                </li>
                                                <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white">
                                                    <span>訊息</span>
                                                </li>
                                            </ul>
                                        </div> -->
                                    </ul>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>
                    </div>
                    <div class="cd-form-group">
                        <div class="flex">
                            <div class="truncate" style="margin-bottom: 2px">
                                <span>{{ item.title }}</span>
                            </div>
                            <div>
                                <span class="ml-2 text-sm text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="openCaseNotePopup(item)">+ {{ $t('crm.addNotes') }}</span>
                            </div>
                        </div>
                        <div style="color: #666" v-if="item.data">
                            <div class="truncate" v-if="item.data.length > 0">
                                <span>●&nbsp;&nbsp;{{ item.data[item.data.length - 1].note }}</span>
                            </div>
                            <div class="truncate" v-if="item.data.length > 1">
                                <span>●&nbsp;&nbsp;{{ item.data[item.data.length - 2].note }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="flex items-center space-x-3 whitespace-no-wrap">
                            <div class="flex items-center" v-for="(tagitem, index) in item.tags" :key="index">
                                <feather-icon icon="TagIcon" svg-classes="w-4 h-4" />
                                <span class="ml-1">{{ tagitem }}</span>
                            </div>
                            <span class="text-sm text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" @click="openTagPopup(item)">+ 服務標籤</span>
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="flex items-center">
                            <div :class="item.status == 0 ? (item.dname ? 'text-success' : '') : ''">
                                <feather-icon class="mr-1" icon="UserIcon" svgClasses="w-4 h-4"></feather-icon>
                                <span>{{ item.dname ? item.dname : $t('crm.unassigned') }}</span>
                            </div>
                            <span class="ml-2 text-primary cursor-pointer hover:font-semibold text-sm" @click="cancelAssign(item)" v-if="item.assignFlag">{{ $t('popup.cancel') }}</span>
                            <span class="ml-2 text-primary cursor-pointer hover:font-semibold text-sm" @click="openAssignPopup(item)" v-else>{{ $t('crm.assign') }}</span>
                        </div>
                        <div class="ml-4 flex items-center" v-if="item.mobile">
                            <feather-icon class="mr-1" icon="PhoneIcon" svgClasses="w-4 h-4"></feather-icon>
                            <a :href="getPhone(item.mobile)">{{ item.mobile }}</a>
                        </div>
                        <div class="ml-4 flex items-center" v-if="item.family_data">
                            <feather-icon class="mr-1" icon="UserIcon" svgClasses="w-4 h-4"></feather-icon>
                            <span class="text-primary"
                                >{{ item.family_data[0].name }} ({{ item.family_data[0].relationship }}) <a :href="getPhone(item.family_data[0].mobile)">{{ item.family_data[0].mobile }}</a></span
                            >
                        </div>
                    </div>
                    <div class="flex flex-wrap">
                        <div class="flex items-center">
                            <feather-icon class="mr-1" icon="ClockIcon" svgClasses="w-4 h-4"></feather-icon>
                            <span class="mr-2">{{ item.created_at }}</span>
                            <span v-if="item.creatorName">{{ $t('crm.createdBy', { name: item.creatorName }) }}</span>
                            <span class="ml-2 text-sm" style="color: black" v-if="item.ename">#{{ item.ename }}</span>
                        </div>
                    </div>
                </div>
                <!-- 查無資料 -->
                <div class="text-center" v-if="total == 0">{{ $t('crm.noData') }}</div>
                <vs-pagination class="flex justify-start" :max="windowWidth <= 600 ? 7 : 11" :total="Math.ceil(total / 20)" v-model="page" @change="fetchServiceRecord" v-if="total != 0"></vs-pagination>
            </vs-col>
        </vs-row>
        <!-- 案件註記 -->
        <vs-popup :title="$t('crm.addNotes')" :active.sync="caseNoteActive" @close="caseNoteClose">
            <div class="px-2">
                <div>
                    <div class="flex justify-end">
                        <importSetWord :dataType="'wd/all'" @importMsg="importMsg($event)"></importSetWord>
                    </div>
                    <vs-textarea rows="3" style="background-color: aliceblue" v-model="serviceNote" />
                    <vs-button class="cd-button-4w" @click="insertServiceRecordDetail(serviceNote)">{{ $t('crm.addNotes') }}</vs-button>
                </div>
                <vs-divider class="my-8" />
                <div>
                    <h5 class="cd-form-group">{{ $t('crm.commentHistory') }}</h5>
                    <div style="height: 40vh" v-if="sdata">
                        <VuePerfectScrollbar style="height: 40vh" :settings="settings">
                            <div class="mb-base flex justify-between items-end" v-for="(item, index) in sdata.data" :key="index">
                                <span>{{ item.note }}</span>
                                <span class="text-sm whitespace-no-wrap" style="color: #666">{{ item.dname }} {{ getTimeAgo(item.created_at.substr(0, 19)) }}</span>
                            </div>
                        </VuePerfectScrollbar>
                    </div>
                </div>
            </div>
        </vs-popup>
        <vs-popup title="服務標籤" :active.sync="tagActive" @close="tagClose">
            <div class="flex items-center">
                <div class="flex flex-wrap" v-if="tagdata">
                    <vs-checkbox v-model="tagdata" :vs-value="item" v-for="(item, index) in caseTags" :key="index">{{ item }}</vs-checkbox>
                </div>
            </div>
            <vs-button class="cd-button-2w mr-4" type="flat" @click="tagClose">{{ $t('popup.cancel') }}</vs-button>
            <vs-button class="cd-button-4w" @click="updateCaseTags(tagdata)">{{ $t('crm.confirm') }}</vs-button>
        </vs-popup>
        <!-- 交辦視窗 -->
        <vs-popup :active.sync="assignActive" :title="$t('crm.caseAssign')" @close="assignClose">
            <vs-input :placeholder="$t('crm.selectCaseOwner')" v-model="searchQuery" class="cd-form-group w-full" />
            <div class="px-2 py-4 flex justify-between items-center cursor-pointer hover:bg-primary hover:text-white" style="line-height: 0" @click="assignCase(did, dname)" v-if="sdata && !sdata.dname">
                <p>{{ dname }}{{ $t('crm.myself') }}</p>
                <div class="flex items-center">
                    <feather-icon icon="PlusIcon" svgClasses="w-4 h-4"></feather-icon>
                </div>
            </div>
            <div class="px-2 py-4 flex justify-between items-center cursor-pointer hover:bg-primary hover:text-white" style="line-height: 0" v-for="(item, index) in getallMedicalNurse" :key="index" @click="assignCase(item.did, item.name)">
                <p>{{ item.name }}</p>
                <div class="flex items-center">
                    <feather-icon icon="PlusIcon" svgClasses="w-4 h-4"></feather-icon>
                </div>
            </div>
        </vs-popup>
        <!-- 通話視窗 -->
        <!-- <call-popup :callActive="callPopupActive" :payload="callPayload" @closePopup="closeCallPopup"></call-popup> -->
        <!-- 新增案件視窗 -->
        <add-case :spid="parseInt(crmPid)" :spname="crmPname" :newCasePopupActive="newCaseActive" @fetchData="fetchServiceRecord" @closePopup="newCaseClose"></add-case>
        <!-- 推薦商品 -->
        <product-popup :productPopupActive="productPopupActive" @closePopup="productClose" :pid="recommandPid" :sn_id="recommsnid" :pname="recommpname"></product-popup>
        <!-- 發送訊息視窗 -->
        <send-chat :chatPopup="openChatPopup" :smid="parseInt(smid)" :sname="sname" @closeChat="closeChatPopup"></send-chat>
        <!-- 掛號視窗 -->
        <appt-btn :activePopup="openApptPopup" :patientData="patient" :mode="apptMode" @closeApptPopup="closeApptPopup"></appt-btn>
        <!-- 通話視窗 -->
        <!-- <call-to :callPopupActive="callPopupActive" :pid="callPayload.spid" @closePopup="closeCallPopup"></call-to> -->
        <call-to :callPopupActive="callPopupActive" :pid="callPid.toString()" :snParams="snParams" @closePopup="closeCallPopup"></call-to>
        <!-- 聯繫照護者視窗 -->
        <!-- <vs-popup :active.sync="contactCaregiverActive" title="照護者" @close="closeContactCaregiverPopup">
            <div v-if="familyData">
                <div class="p-4 flex justify-between" v-for="(data,index) in familyData" :key="index">
                    <span>{{ data.name }} ({{ data.relationship }})</span>
                    <div class="flex items-center">
                        <feather-icon class="mr-1" icon="PhoneIcon" svgClasses="w-4 h-4"></feather-icon>
                        <a :href="getPhone(data.mobile)">{{ data.mobile }}</a>
                    </div>
                </div>
            </div>
        </vs-popup> -->
        <vs-popup :title="$t('crm.closeAll')" :active.sync="DialogFinishAllCase" :width="windowWidth > 768 ? '50%' : '80%'" center>
            <span>{{ $t('crm.selectCase') }}</span>
            <div style="text-align: center" class="flex justify-center">
                <vs-checkbox class="cd-form-group" v-model="finishOwnCase" disabled>{{ $t('crm.assignCase') }}</vs-checkbox>
                <vs-checkbox class="cd-form-group" v-model="finishAllCase">{{ $t('crm.unassignedCase') }}</vs-checkbox>
            </div>
            <div style="text-align: center" class="flex justify-end">
                <vs-button class="cd-button-2w" @click="closeAllCase">{{ $t('crm.close') }}</vs-button>
                <vs-button class="cd-button-2w mr-4" type="flat" @click="DialogFinishAllCase = false">{{ $t('popup.cancel') }}</vs-button>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import { fetchServiceRecord, updatecaseStatus, insertServiceRecordDetail, fetchAuthList, updatecaseowner, closeAllCase, fetchCaseTags, updateCaseTags } from '@/api/user'
import importSetWord from '@/components/importSetWord'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import callPopup from '@/components/callPopup'
import addCase from '@/components/addCase'
import productPopup from '@/components/productPopup.vue'
import sendChat from '@/components/sendChat'
import apptBtn from '../components/apptBtn.vue'
import callTo from '@/components/callTo'
import { debounce } from 'lodash'
export default {
    components: {
        VuePerfectScrollbar,
        importSetWord,
        callPopup,
        addCase,
        productPopup,
        sendChat,
        apptBtn,
        callTo,
    },
    props: {
        userPid: {
            type: String,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            srData: [],
            caseNoteActive: false,
            assignActive: false,
            callPopupActive: false,
            newCaseActive: false,
            productPopupActive: false,
            tagActive: false,
            serviceNote: '',
            sdata: null,
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            searchQuery: '',
            myMedicalAllNurse: [],
            tempMyMedicalAllNurse: [],
            callPayload: {},
            query: '',
            nodataFlag: 0,
            loadingFlag: false,
            page: 1,
            total: 0,
            caseTags: [],
            recommandPid: 0,
            recommsnid: 0,
            recommpname: '',
            allowshopstatus: '',
            // contactCaregiverActive: false,
            openChatPopup: false,
            smid: 0,
            sname: '',
            openApptPopup: false,
            patient: {
                pid: null,
                name: null,
            },
            apptMode: 0,
            crmPid: null,
            crmPname: null,
            // familyData: null
            callPid: '',
            snParams: {},
            trackDays: 0,
            DialogFinishAllCase: false,
            finishOwnCase: true,
            finishAllCase: false,
            tagdata: [],
            tagcaseid: 0,
        }
    },
    mounted() {
        this.fetchServiceRecord()
        this.fetchAuthList()
        this.$store.dispatch('fetchSRQuantity', { partnerid: this.partnerid })
        this.allowshopstatus = localStorage.getItem('cart_token')
        this.fetchCaseTags()
        // window.addEventListener('scroll', this.scrolling)
    },
    watch: {
        query(n) {
            this.page = 1
            this.fetchServiceRecord()
        },
        searchQuery(n) {
            if (n != '') {
                var temp = _.filter(this.myMedicalAllNurse, function (o) {
                    return o.name.includes(n)
                })
                this.tempMyMedicalAllNurse = _.cloneDeep(temp)
            } else {
                this.tempMyMedicalAllNurse = _.cloneDeep(this.myMedicalAllNurse)
            }
        },
        crmNewCase(n) {
            if (n == true) this.fetchServiceRecord()
        },
    },
    computed: {
        partnerid() {
            return this.$store.getters.partner
        },
        did() {
            return this.$store.getters.did
        },
        dname() {
            return this.$store.getters.name
        },
        // 所有同屬機構醫事人員
        getallMedicalNurse() {
            if (!this.tempMyMedicalAllNurse) return []
            // 最多顯示10筆資料
            return this.tempMyMedicalAllNurse.slice(0, 10)
        },
        // 會員資料
        // spname() {
        //     return this.$store.getters.userEMRBaseData[0] ? this.$store.getters.userEMRBaseData[0].name : null;
        // },
        crmNewCase() {
            return this.$store.state.crmNewCase
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
    },
    methods: {
        // 取服務記錄
        fetchServiceRecord: debounce(function (event) {
            let payload = {
                partnerid: this.partnerid,
                page: this.page,
            }
            if (this.$route.path == '/crm') payload.sdid = this.did
            if (this.userPid) payload.spid = this.userPid
            if (this.query) payload.query = this.query
            fetchServiceRecord(payload).then((res) => {
                this.srData = res.data.data
                this.total = res.data.total
                if (!res.data.data) this.nodataFlag = 1
                this.loadingFlag = false
                this.$store.commit('SET_CRM_NEW_CASE', false)
            })
            window.scrollTo(0, 0)
        }, 300),
        // 取追蹤天數
        getTrackDays(date) {
            if (!date) return ''
            var date = new Date(date)
            var now = new Date()
            date = this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
            now = this.$moment(now).format('YYYY-MM-DD HH:mm:ss')
            var endDate = date.replace(/-/g, '/')
            var startDate = now.replace(/-/g, '/')
            var diff = 0
            diff = new Date(endDate) - new Date(startDate)
            var days = Math.floor(diff / (24 * 3600 * 1000)) + 1
            if (days == 1) {
                return ' ' + this.$t('crm.tomorrow')
            } else if (days == 0) {
                return ' ' + this.$t('crm.today')
            } else if (days > 0) {
                return ' ' + this.$t('crm.days', { days: days })
            } else if (days < 0) {
                return ' ' + this.$t('crm.daysAgo', { days: -days })
            } else {
                return ''
            }
        },
        // 匯入常用字句
        importMsg(msg) {
            // 判斷最後一個字元是不是換行符號
            let lastWord = this.serviceNote.split('')[this.serviceNote.split('').length - 1]
            if (lastWord != '\n' && lastWord != undefined) {
                this.serviceNote += '\n'
            }
            this.serviceNote += msg
        },
        // 更新案件狀態
        updateStatus: debounce(function (case_id, days, index, autoCloseDropdown = true) {
            let payload = { caseid: case_id }
            payload.status = days == 0 ? 1 : 0
            if (days != 0) {
                let date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + days)
                date = this.$moment(date).format('YYYY-MM-DD')
                payload.tracked_at = date
            }
            updatecaseStatus(payload).then((res) => {
                this.notify('grey', this.$t('message.update_success'))
                let index = _.findIndex(this.srData, ['id', case_id])
                this.srData[index].status = payload.status
                this.srData[index].tracked_at = payload.tracked_at
                this.$store.dispatch('fetchSRQuantity', { partnerid: this.partnerid })
            })
            if (autoCloseDropdown) this.$refs.myDropdown[index].$children[0].dropdownVisible = false
        }, 300),
        // 視窗通知
        notify(color, title, text = '') {
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                position: 'top-center',
            })
        },
        // 開啟註記視窗
        openCaseNotePopup(data) {
            if (data.assignFlag) return
            this.sdata = _.cloneDeep(data)
            this.sdata.data = _.orderBy(data.data, ['created_at'], ['desc'])
            document.body.style.overflow = 'hidden'
            this.caseNoteActive = true
        },
        // 關閉註記視窗
        caseNoteClose() {
            document.body.style.overflow = 'visible'
            this.caseNoteActive = false
        },
        // 開啟註記視窗
        openTagPopup(data) {
            if (data.tags) {
                this.tagdata = data.tags
            } else {
                this.tagdata = []
            }
            this.tagcaseid = data.id
            document.body.style.overflow = 'hidden'
            this.tagActive = true
        },
        tagClose() {
            document.body.style.overflow = 'visible'
            this.tagActive = false
        },
        // 取服務案件標籤
        fetchCaseTags() {
            let payload = {
                partner_id: this.partnerid,
            }
            fetchCaseTags(payload).then((res) => {
                this.caseTags = res.data.data
            })
        },
        updateCaseTags(data) {
            data = data.filter((item) => item !== null)
            let payload = {
                case_id: this.tagcaseid,
                tags: data,
            }
            updateCaseTags(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.fetchServiceRecord()
                    this.tagClose()
                }
            })
        },
        // 開啟該病人的病歷
        goToMr(data) {
            let patientJson = {
                name: data.pname,
                pid: data.pid,
            }
            this.$store.commit('SET_ACTIVE_PATIENT_INFO', patientJson)
            let routerPayload = {
                path: '/emr2/' + data.pid + '/0?index=crm',
            }
            this.$router.push(routerPayload)
        },
        // 新增註記
        insertServiceRecordDetail(note) {
            var payload = {
                case_id: this.sdata.id,
                notes: note,
                spid: this.sdata.pid,
            }
            insertServiceRecordDetail(payload).then((res) => {
                if (res.data.status == 'OK') {
                    if (res.data.items > 0) this.notify('grey', this.$t('message.create_success'))
                    let newNote = {
                        did: this.did,
                        note: note,
                        dname: this.dname,
                        created_at: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                    }
                    let index = _.findIndex(this.srData, ['id', this.sdata.id])
                    if (!this.srData[index].data) this.srData[index].data = []
                    this.srData[index].data.push(newNote)
                    this.sdata.data.unshift(newNote)
                    this.serviceNote = ''
                } else {
                    this.notify('danger', this.$t('message.create_error') + this.$t('message.checkField'))
                }
            })
        },
        // 取醫師可授權的醫事人員名單
        fetchAuthList() {
            fetchAuthList(this.partnerid).then((res) => {
                this.myMedicalAllNurse = _.cloneDeep(res.data.data)
                this.tempMyMedicalAllNurse = _.cloneDeep(res.data.data)
            })
        },
        // 開啟交辦案件視窗
        openAssignPopup(data) {
            this.sdata = _.cloneDeep(data)
            document.body.style.overflow = 'hidden'
            this.assignActive = true
        },
        // 關閉交辦案件視窗
        assignClose() {
            document.body.style.overflow = 'visible'
            this.assignActive = false
        },
        // 交辦案件
        assignCase(sdid, sname) {
            let payload = {
                caseid: this.sdata.id,
                ownerdid: sdid,
                spid: this.sdata.pid,
            }
            updatecaseowner(payload)
                .then((res) => {
                    this.notify('grey', this.$t('message.assign_success'))
                    let index = _.findIndex(this.srData, { id: this.sdata.id })
                    this.srData[index].dname = this.$t('crm.assignTo', { from_name: this.sdata.dname, to_name: sname })
                    this.srData[index].assignFlag = true
                    this.assignClose()
                })
                .then(() => {
                    var note = this.$t('crm.assignMsg', { from_name: this.dname, to_name: sname })
                    this.insertServiceRecordDetail(note)
                })
        },
        // 取消交辦
        cancelAssign(data) {
            let payload = {
                caseid: data.id,
                ownerdid: this.did,
            }
            updatecaseowner(payload)
                .then((res) => {
                    this.notify('grey', this.$t('crm.cancel'))
                    let index = _.findIndex(this.srData, { id: data.id })
                    this.srData[index].dname = this.dname
                    this.srData[index].assignFlag = false
                })
                .then(() => {
                    var note = this.$t('crm.cancelAssignMsg', { from_name: this.dname })
                    this.insertServiceRecordDetail(note)
                })
        },
        // 開啟 通話視窗
        // showCallPopup(data){
        //     this.callPayload = {
        //         "spid":  data.pid.toString(),
        //         "pname": data.pname,
        //     }
        //     this.callPopupActive = true
        // },
        // 關閉 通話視窗
        closeCallPopup() {
            document.body.style.overflow = 'visible'
            this.callPopupActive = false
        },
        // 開啟新增客服案件視窗
        openNewCasePopup(pid, name) {
            document.body.style.overflow = 'hidden'
            this.crmPid = pid
            this.crmPname = name
            this.newCaseActive = true
        },
        // 關閉新增客服案件視窗
        newCaseClose() {
            document.body.style.overflow = 'visible'
            this.newCaseActive = false
        },
        //開啟推薦商品視窗
        openProductPopup(pid, id, name) {
            this.recommandPid = pid
            this.recommsnid = id
            this.recommpname = name
            document.body.style.overflow = 'hidden'
            this.productPopupActive = true
        },
        //關閉推薦商品視窗
        productClose() {
            document.body.style.overflow = 'visible'
            this.productPopupActive = false
        },
        // 滾動時觸發
        // scrolling(){
        //     let box = document.querySelector('.list')
        //     let bottomOfWindow = box.offsetHeight - document.documentElement.scrollTop - window.innerHeight
        //     if(bottomOfWindow <= 200 && this.nodataFlag == 0 && !this.loadingFlag){
        //         this.loadingFlag = true
        //         this.page++
        //         let payload = {
        //             "partnerid": this.partnerid,
        //             "page": this.page,
        //         }
        //         if(this.$route.path == '/crm') payload.sdid = this.did
        //         if(this.userPid) payload.spid = this.userPid
        //         fetchServiceRecord(payload).then((res) => {
        //             this.srData = _.concat(this.srData, res.data.data)
        //             if(!res.data.data) this.nodataFlag = 1
        //             this.loadingFlag = false
        //         })
        //     }
        // },
        // 直接打電話 轉成href格式
        getPhone(number) {
            number = number.slice(2, 10)
            number = 'tel:+886-9' + number
            return number
        },
        // 取醫師可授權的醫事人員名單
        fetchAuthList() {
            fetchAuthList(this.partnerid).then((res) => {
                this.myMedicalAllNurse = _.cloneDeep(res.data.data)
                this.tempMyMedicalAllNurse = _.cloneDeep(res.data.data)
            })
        },
        // 取時間多久前
        getTimeAgo(timestamp) {
            const now = Date.now()
            const then = Date.parse(timestamp.replace(/-/g, '/'))
            const seconds = Math.floor((now - then) / 1000)
            const minutes = Math.floor(seconds / 60)
            const hours = Math.floor(minutes / 60)
            const days = Math.floor(hours / 24)
            const months = Math.floor(days / 30)
            const years = Math.floor(months / 12)

            if (seconds < 60) {
                return this.$t('crm.sec', { num: seconds })
            } else if (minutes < 60) {
                return this.$t('crm.min', { num: minutes })
            } else if (hours < 24) {
                return this.$t('crm.hour', { num: hours })
            } else if (days < 30) {
                return this.$t('crm.day', { num: days })
            } else if (months < 12) {
                return this.$t('crm.month', { num: months })
            } else {
                return this.$t('crm.year', { num: years })
            }
        },
        // 開啟 聯繫照護者視窗
        // showContactCaregiverPopup(data){
        //     this.familyData = data
        //     this.contactCaregiverActive = true
        // },
        // 關閉 聯繫照護者視窗
        // closeContactCaregiverPopup(){
        //     this.contactCaregiverActive = false
        // },
        // 開啟 傳送訊息視窗
        showChatPopup(mid, name) {
            document.body.style.overflow = 'hidden'
            this.smid = mid
            this.sname = name
            this.openChatPopup = true
        },
        // 關閉 傳送訊息視窗
        closeChatPopup(v) {
            document.body.style.overflow = 'visible'
            this.smid = 0
            this.openChatPopup = false
        },
        // 開啟 掛號視窗
        showApptPopup(index, pid = null, pname = null) {
            this.openApptPopup = true
            if (pid && pname) {
                this.patient.pid = parseInt(pid)
                this.patient.name = pname
            } else {
                this.patient = null
            }
            this.apptMode = index
        },
        // 關閉 掛號視窗
        closeApptPopup(v) {
            this.openApptPopup = false
        },
        // 全部結案警告視窗
        closeAllCaseDialog() {
            this.DialogFinishAllCase = true
            // this.$vs.dialog({
            //     type: 'confirm',
            //     color: 'danger',
            //     title: this.$t('popup.alert'),
            //     text: this.$t('crm.confirmAllClose'),
            //     accept: this.closeAllCase,
            //     acceptText: this.$t('popup.confirm'),
            //     cancelText: this.$t('popup.cancel'),
            // })
        },
        // 全部結案
        closeAllCase() {
            var note = this.$t('crm.detailFinish', { dname: this.dname })
            const payload = {
                partner_id: this.partnerid,
                all_case: this.finishAllCase,
                notes: note,
            }
            closeAllCase(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.$store.dispatch('notify', { color: 'grey', title: this.$t('crm.close_success') })
                    this.fetchServiceRecord()
                    this.$store.dispatch('fetchSRQuantity', { partnerid: this.partnerid })
                }
                this.DialogFinishAllCase = false
            })
        },
        // getCardStyle(status, assigned){
        //     if(status == 1) {
        //         if(assigned == true) return 'background-color: #2D9B32; border: #eee 1px solid;'
        //         return 'background-color: #fee; border: #eee 1px solid;'
        //     }
        //     return ''
        // }
        // 開啟 通話視窗
        showCallPopup(data) {
            document.body.style.overflow = 'hidden'
            this.callPid = data.pid
            this.snParams = data
            this.callPopupActive = true
        },
    },
    // destroyed () {
    //     window.removeEventListener('scroll', this.scrolling)
    // },
}
</script>
